import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_10/slide3';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "slide-4-10-3"}}) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_10/mobile/3.png"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
